import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { getLocalizedPath, getCurrentLangKey } from '../core/i18n';
import i18n from '../data/i18n';

import Menu from '../components/menu';
import Layout from '../components/layout';

import StyledButton from '../style/common/styledButton';
import StyledWrapper from '../style/home/styledWrapper';
import StyledContentWrapper from '../style/home/styledContentWrapper';
import StyledContent from '../style/home/styledContent';
import StyledImage from '../style/home/styledImage';

const NotFoundPage = props => {
  const { home } = props.data;
  const url = props.location.pathname;
  const locale = getCurrentLangKey(i18n.langs, i18n.defaultLangKey, url);
  return (
    <Layout>
      <StyledImage>
        <Img sizes={home.backgroundImage.sizes} />
      </StyledImage>

      <StyledWrapper>
        <Menu />
        <StyledContentWrapper>
          <StyledContent>
            <div
              dangerouslySetInnerHTML={{
                __html: '<p><strong>404</strong></p>',
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: '<p>You just hit a route that doesn&#39;t exist... the sadness.</p>',
              }}
            />
            <div>
              <StyledButton large>
                <Link to={getLocalizedPath('', locale)}>back to home</Link>
              </StyledButton>
            </div>
          </StyledContent>
        </StyledContentWrapper>
      </StyledWrapper>
    </Layout>
  );
};

NotFoundPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export const query = graphql`
  query NotFoundPageQuery {
    home: datoCmsHome(locale: { eq: "nl" }) {
      backgroundImage {
        sizes(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
    }
  }
`;

export default NotFoundPage;
